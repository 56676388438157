import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import {
  Row,
  Col,
  Avatar,
  Table,
  message,
  Button,
  Input,
  Dropdown,
  Radio,
  Switch,
  Tooltip,
} from "antd";
import EditIcon from "../../Assets/advert_detail/editBox.svg";
import DeleteIconRed from "../../Assets/advert_detail/deleteBox.svg";
import SearchIcon from "../../Assets/campagin/searchIcon.svg";
import DownLoad from "../../Assets/advert_detail/download.svg";
import {
  CommonDeleteSvg,
  TableDeleteImage,
} from "../../Assets/campagin/sectionProgress/svgs";
import NoElementBox from "../../components/common/NoElementBox";
import FilterIcon from "../../Assets/filter.svg";
import { JobActiveDeactivateModal } from "../../components/common/Popup";
import { useNavigate } from "react-router-dom";
import Icon1 from "../../Assets/campagin/cards/icon1.svg";
import Icon2 from "../../Assets/campagin/cards/icon2.svg";
import infoIcon from "../../Assets/advert_detail/infoIcon.svg";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import action_delete from "../../Assets/advert_detail/action-delete.svg";
import moment from "moment";
import rightArrow from "../../Assets/campagin/right_arrow.svg";
import EditActionIcon from "../../Assets/advert_detail/editIcon.svg";
import CancelIcon from "../../Assets/cancelIcon.svg";
import { DeleteModal, DeleteAdvertModal } from "../../components/common/Popup";
import CustomPagination from "../../components/common/CustomPagination";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { downloadPDF } from "../../utils/constant";
import MobileHeader from "../../components/layout/MobileHeader";
function CampaignDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [campaignCount, setCampaignCount] = useState(0);
  const [jobModal, setJobModal] = useState(false);
  const [jobName, setJobName] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("-createdAt");
  const [advertData, setAdvertData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobActive, setJobActive] = useState(true);
  const [isActive, setisActive] = useState(); //to pass api data in modal
  const [advertId, setadvertId] = useState(); //to pass api data in modal
  const [rowData, setRowData] = useState({});
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [activeCampaignDeleteModal, setActiveCampaignDeleteModal] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [campaignDeleteLoading, setCampaignDeleteLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [deleteType, setDeleteType] = useState("single");
  const [currentPage, setCurrentPage] = useState(1);
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  /************to show delete and status icon color diffrent when checkbox is selected ****************/
  const hasSelected = selectedRowKeys.length > 0;
  const handleSwitch = (id, isActive) => {
    setJobActive(!isActive);
    setJobModal(true);
  };
  const tableScrollConfig = {
    y: 300, // Set the height at which the table will start scrolling
    x: "calc(750px + 60%)", // Set the horizontal scrolling
  };
  const getAdvertData = async (id) => {
    setLoading(true);
    let params = {
      page: currentPage,
      limit: 10,
      sort: filterType,
    };
    if (search) {
      params = { ...params, ["search"]: search };
    }
    try {
      const advertResponse = await API_MANAGER.getCampaignAdverts(id, params);
      setLoading(false);
      setAdvertData(advertResponse?.data?.data);
      setCampaignCount(advertResponse?.data?.data?.result?.length);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };
  // const advertArchive = async () => {
  //   try {
  //     const param = {
  //       archive: rowData?.archive ? false : true,
  //     };
  //     await API_MANAGER.advertArchive(rowData?._id, param);
  //     message.success(
  //       `Advert successfully ${rowData?.archive ? "unarchived" : "archived"}.`
  //     );
  //     getAdvertData(id);
  //   } catch (error) {
  //     message.error("Something went wrong. Please try again.");
  //   }
  // };
  const deleteAdvert = async () => {
    setDeleteLoading(true);
    try {
      if (deleteType === "single") {
        await API_MANAGER.deleteAdvert(rowData?._id);
        message.success("Advert successfully deleted.");
      } else if (deleteType === "multiple") {
        const param = {
          advertIds: selectedRowKeys,
        };
        await API_MANAGER.bulkAdvertDelete(param);
        message.success("Adverts succcessfully deleted.");
      }
      setCurrentPage(1);
      getAdvertData(id);
      getData();
      setDeleteLoading(false);
      setActiveDeleteModal(false);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      setDeleteLoading(false);
      setActiveDeleteModal(false);
    }
  };
  const deleteCampaign = async () => {
    setCampaignDeleteLoading(true);
    try {
      await API_MANAGER.deleteCampaign(data?._id);
      message.success("Campaign successfully deleted.");
      setCampaignDeleteLoading(false);
      navigate("/campaign-management");
    } catch (error) {
      setCampaignDeleteLoading(false);
      message.success("Something went wrong. Please try again.");
    }
  };

  const getActionItems = (row) => {
    const actionItems = [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            <Row>
              <Col
                className="item"
                span={24}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/create-advert?id=${row?.id}`, { state: data });
                }}
              >
                <img src={EditActionIcon} />
                <span>Edit</span>
              </Col>
            </Row>
            <Row>
              <Col
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteType("single");
                  setActiveDeleteModal(true);
                }}
                className="item"
                span={24}
              >
                <img src={action_delete} />
                <span>Delete</span>
              </Col>
            </Row>
            {/* <Row>
              <Col className="item" span={24} onClick={() => advertArchive()}>
                <img src={ArchiveIcon} />
                <span>{row?.archive ? "Unarchive" : "Archive"}</span>
              </Col>
            </Row> */}
          </div>
        ),
      },
    ];
    return actionItems;
  };
  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          onChange={(e) => setFilterType(e?.target?.value)}
          value={filterType}
          className="dropDown_radio_group"
        >
          <div className="">
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio defaultChecked={true} value={"-createdAt"}>
                  Latest First
                </Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"createdAt"}>Oldest First</Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"jobTitle"}>Sort by A-Z</Radio>
              </Col>
            </Row>
            <Row className="filter_item_row">
              <Col span={24} className="">
                <Radio value={"-jobTitle"}>Sort by Z-A</Radio>
              </Col>
            </Row>
          </div>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Row
          gutter={16}
          align={"middle"}
          className="d-flex wrap-unset align_center_start"
        >
          <Col className="campaign-table-heading">Job Title</Col>
          <Col>
            <Dropdown
              menu={{ items }}
              overlayClassName="notificationFilterDropdown"
            >
              <img src={FilterIcon} alt="filter" />
            </Dropdown>
          </Col>
        </Row>
      ),
      dataIndex: "jobTitle",
      render: (item, row) => (
        <Tooltip title={item?.name}>
          <p
            style={{
              marginTop: "15px",
              maxWidth: "150px",
              minWidth: "100px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              background: "white",
              whiteSpace: "nowrap",
              color: row?.isActive ? "#091647" : "#BCC3D0",
              textAlign: "left",
            }}
            className="cursor-pointer"
            onClick={() => navigate(`/advert-detail/${row?.id}`)}
          >
            {item?.name}
          </p>
        </Tooltip>
      ),
      fixed: "left",
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (item, row) => (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            checked={row?.isActive}
            onChange={() => {
              setisActive(row?.isActive);
              setadvertId(row?.id);
              setJobName(row?.jobTitle?.name);
              handleSwitch(row?.id, row?.isActive);
            }}
          />
        </span>
      ),
    },
    {
      title: (
        <div>
          Target<br></br> Headcount
        </div>
      ),
      dataIndex: "targetHeadcount",
    },
    {
      title: "Invited",
      dataIndex: "invites",
    },
    {
      title: "Applied",
      dataIndex: "applied",
    },
    {
      title: (
        <div>
          Shortlists<br></br> Offered
        </div>
      ),
      dataIndex: "shortlistOffered",
    },
    {
      title: (
        <div>
          Hard<br></br> Review
        </div>
      ),
      dataIndex: "hardReview",
    },
    {
      title: (
        <div>
          Role<br></br> Offered
        </div>
      ),
      dataIndex: "roleOffered",
    },
    {
      title: (
        <div>
          Role<br></br>Accepted
        </div>
      ),
      dataIndex: "roleAccepted",
    },
    {
      title: "",
      dataIndex: "action",
      render: (item, row) => (
        <Row align={"middle"}>
          <Dropdown
            placement="left"
            overlayClassName="action-dropdown"
            menu={{
              items: getActionItems(row),
            }}
            trigger={"click"}
          >
            <img
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setRowData(row);
              }}
              className="cursor_pointer"
              src={infoIcon}
              alt="edit"
            />
          </Dropdown>
        </Row>
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getCampaignDetail(id);
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong. Please try again.");
    }
  };
  const changeAdvertStatus = async (advertId, isActive) => {
    setChangeStatusLoading(true);
    const body = {
      isActive: isActive ? false : true,
    };
    try {
      await API_MANAGER.changeAdvertStatus(advertId, body);
      setChangeStatusLoading(false);
      message.success("Status successfully updated.");
      setJobModal(false);
      getAdvertData(id);
    } catch (error) {
      message.error("Something went wrong. Please try again.");

      setChangeStatusLoading(false);
    }
  };
  const handleModalCancel = () => {
    setJobModal(false);
    getAdvertData(id);
  };
  useEffect(() => {
    setJobModal(false);
    getData();
  }, []);

  useEffect(() => {
    getAdvertData(id);
  }, [search, filterType, currentPage]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const advertReport = async () => {
    setReportLoading(true);
    try {
      const response = await API_MANAGER.getAdvertReport(id);
      downloadPDF(response?.data?.data, "Advert Report");
      setReportLoading(false);
    } catch (error) {
      setReportLoading(false);
      message.error(
        error?.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <SubLayout page={"Campaign Details"} whiteBg showBack showSearch>
      {/* <FullScreenLoader isLoading={loading} /> */}
      <div className="campaign_detail_container">
        {/* for small screen only */}
        <Row>
          <Col xs={24} md={0}>
            <MobileHeader title={"Campaign Details"} />
          </Col>
        </Row>
        <div className="campaign_detail_box">
          {/* compaign details profile card row 1 */}
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              <Row gutter={{ xs: 8, md: 20 }} align={"middle"}>
                <Col>
                  <Avatar
                    style={{ width: "69px", height: "69px" }}
                    size={26}
                    className="campaign-avatar"
                    src={data?.companyId?.companyLogo}
                  >
                    {/* <span className="text"> */}
                    {data?.name?.substring(0, 1).toUpperCase() || ""}
                    {/* </span> */}
                  </Avatar>
                </Col>
                <Col className="name">
                  {data?.name?.charAt(0)?.toUpperCase() +
                    data?.name?.substring(1) || ""}
                </Col>
              </Row>
            </Col>
            <Col>
              <img
                src={EditIcon}
                alt="edit"
                className="cursor-pointer"
                onClick={() =>
                  navigate("/campaign-management/edit-campaign", {
                    state: data,
                  })
                }
              />
              <img
                src={DeleteIconRed}
                alt="delte icon"
                className="deleteIcon cursor-pointer"
                onClick={() => setActiveCampaignDeleteModal(true)}
              />
            </Col>
          </Row>

          <div className="detail_row">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col xs={12} md={4}>
                <p className="title">Company Name</p>
                <p className="detail">
                  {data?.companyId?.companyName?.charAt(0)?.toUpperCase() +
                    data?.companyId?.companyName?.substring(1) || ""}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p className="title">Industry</p>
                <p className="detail">{data?.industry?.name || ""}</p>
              </Col>
              <Col xs={0} md={7}>
                <p className="title">Location</p>
                <p className="detail">
                  {`${data?.suburb || ""}${
                    data?.state?.name ? `, ${data?.state?.name}` : ""
                  }, ${data?.postalCode || ""}`}{" "}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p className="title">Created date</p>
                <p className="detail text_uppercase">
                  {moment(data?.createdAt).format("DD MMM YYYY")}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p className="title">No. of users</p>
                <p className="detail">
                  {data?.users?.length ? data?.users?.length : 0}
                </p>
              </Col>
              <Col xs={24} md={0}>
                <p className="title">Location</p>
                <p className="detail">{`${data?.suburb}${
                  data?.state?.name ? `, ${data?.state?.name}` : ""
                }, ${data?.postalCode}`}</p>
              </Col>
            </Row>
          </div>
        </div>
        {/******************** cards section ***************/}
        {data && (
          <div className="mt-20">
            <h3 className="campaign-summary-heading">Campaign Summary</h3>
            <Row justify="space-between">
              <Col xs={24} lg={10}>
                <div className="campaignCard campaignDetailCardOne">
                  <Row gutter={16} style={{ height: "100%" }}>
                    <Col xs={17} lg={15}>
                      <Row gutter={16} align={"middle"}>
                        <Col>
                          <img src={Icon2} alt="img" />
                        </Col>
                        <Col>
                          <p className="title">Target Headcount</p>
                        </Col>
                      </Row>
                      <p className="amount">
                        {data?.targetHeadCount ? data?.targetHeadCount : 0}
                      </p>
                    </Col>
                    <Col xs={7} lg={9}>
                      <div className="detailBox">
                        <Row align={"middle"} style={{ height: "100%" }}>
                          <div className="w-100">
                            <p className="data">
                              {data?.rolesAccepted ? data?.rolesAccepted : 0}/
                              {data?.targetHeadCount
                                ? data?.targetHeadCount
                                : 0}
                            </p>
                            <p className="detail">Total Accepted</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={24} lg={12} className="lg-mt-20">
                <div className="campaignCard campaignDetailCardTwo">
                  <Row gutter={16}>
                    <Col span={8}>
                      <div className="detailBox">
                        <Row justify={"center"}>
                          <img src={Icon1} alt="img" />
                        </Row>
                        <Row className="mt-16">
                          <p className="data">
                            {data?.appliedJobsCount
                              ? data?.appliedJobsCount
                              : 0}
                          </p>
                          <p className="detail">Applied</p>
                        </Row>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="detailBox">
                        <Row justify={"center"}>
                          <img src={Icon1} alt="img" />
                        </Row>
                        <Row className="mt-16">
                          <p className="data">
                            {data?.hardReview ? data?.hardReview : 0}
                          </p>
                          <p className="detail">Hard Review</p>
                        </Row>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="detailBox">
                        <Row justify={"center"}>
                          <img src={Icon1} alt="img" />
                        </Row>
                        <Row className="mt-16">
                          <p className="data">
                            {data?.roleOffered ? data?.roleOffered : 0}
                          </p>
                          <p className="detail">Role Offered</p>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {campaignCount > 0 || search !== null ? (
          <div className="campaign_summary_box">
            <div className="campaign_table_box">
              <Table
                scroll={tableScrollConfig}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={advertData?.result}
                pagination={false}
                className="campaignDetailTable"
                loading={!search ? loading : false}
                // scroll={{
                //   x: "calc(750px + 60%)",

                // }}
                title={() => (
                  <Row
                    align={"middle"}
                    justify={"space-between"}
                    className="campaignTableTitle"
                  >
                    <Col xs={6} md={9}>
                      <Row
                        gutter={{ lg: 16, xl: 32 }}
                        align={"middle"}
                        className="align-end-sm"
                      >
                        <Col>
                          <Row
                            className="cursor-pointer"
                            align={"middle"}
                            gutter={4}
                            onClick={() => {
                              if (
                                selectedRowKeys?.length > 0 &&
                                selectedRowKeys.length <= 1
                              ) {
                                setDeleteType("single");
                                setActiveDeleteModal(true);
                              } else if (selectedRowKeys?.length > 1) {
                                setDeleteType("multiple");
                                setActiveDeleteModal(true);
                              }
                            }}
                          >
                            <Col style={{ marginTop: "4px" }}>
                              {/* <img src={DeleteImg} alt="delete" /> */}
                              <CommonDeleteSvg
                                color={hasSelected ? "#D03C28" : "#A7AEBB"}
                              />
                            </Col>
                            <Col
                              className={`${
                                hasSelected ? "deleteRed" : "delete"
                              } dp-none-sm`}
                            >
                              Delete
                            </Col>
                          </Row>
                        </Col>
                        {subscription &&
                          subscription?.sp?.active &&
                          JSON.parse(
                            localStorage.getItem("isPlusSubscription")
                          ) && (
                            <Col>
                              <Row
                                align={"middle"}
                                gutter={4}
                                onClick={() => advertReport()}
                              >
                                <Col>
                                  <img
                                    src={DownLoad}
                                    alt="campaign-report cursor-pointer"
                                  />
                                </Col>
                                <Col className="Campaign-Report dp-none-sm cursor-pointer">
                                  {reportLoading
                                    ? "Downloading..."
                                    : "Advert Report"}
                                </Col>
                              </Row>
                            </Col>
                          )}
                      </Row>
                      {/* ******************************** */}
                    </Col>
                    <Col xs={18} md={15} align={"right"}>
                      <Row gutter={16} align={"right"}>
                        <Col xs={24} md={16}>
                          <Input.Search
                            className="searchBox"
                            placeholder="Search job advert"
                            value={search}
                            prefix={<img src={SearchIcon} alt="search" />}
                            onChange={(e) => setSearch(e?.target?.value)}
                            suffix={
                              search && (
                                <img
                                  src={CancelIcon}
                                  className="cursor_pointer"
                                  alt="cross"
                                  onClick={() => {
                                    setSearch(null);
                                  }}
                                />
                              )
                            }
                          />
                        </Col>
                        <Col xs={0} md={8}>
                          <Button
                            onClick={() =>
                              navigate(`/create-advert`, {
                                state: { advertDetail: data },
                              })
                            }
                            className="createBtn"
                          >
                            + Create Advert
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                rowKey={"id"}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate(`/advert-detail/${record?.id}`);
                    }, // click row
                  };
                }}
              />
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={advertData?.totalCount}
                itemPerPage={10}
              />

              <Row>
                <Col xs={24} md={0} className="mt-16 w-100">
                  <Button
                    onClick={() =>
                      navigate(`/create-advert`, {
                        state: { advertDetail: data },
                      })
                    }
                    className="createBtn createBtn-sm"
                  >
                    + Create Advert
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <NoElementBox
            title="No adverts created yet!"
            // description="Click below on the “Create advert button” to create an advert."
            btnText="+ Create Advert"
            handleClick={() => navigate(`/create-advert`, { state: data })}
          />
        )}
        <JobActiveDeactivateModal
          isActive={isActive}
          advertId={advertId}
          visible={jobModal}
          setVisible={setJobModal}
          handleSubmit={changeAdvertStatus}
          loading={changeStatusLoading}
          onCancel={handleModalCancel}
          jobName={jobName}
        />
        <DeleteAdvertModal
          visible={activeDeleteModal}
          setVisible={setActiveDeleteModal}
          text={deleteType === "single" ? "Advert" : "Adverts"}
          handleSubmit={deleteAdvert}
          loading={deleteLoading}
        />
        <DeleteModal
          visible={activeCampaignDeleteModal}
          setVisible={setActiveCampaignDeleteModal}
          text={"Campaign"}
          handleSubmit={deleteCampaign}
          loading={campaignDeleteLoading}
        />
      </div>
    </SubLayout>
  );
}

export default CampaignDetail;
